import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import reactStringReplace from 'react-string-replace';
import { Typography } from '@popmenu/common-ui';

import { useQuery } from '~/lazy_apollo/client';
import { PopmenuTermsDocument } from '~/libs/gql/queries/restaurants/popmenuTermsQuery.generated';
import type { PopmenuTermsQuery_popmenuTerms_PopmenuTerms as PopmenuTermsType } from '~/libs/gql/queries/restaurants/popmenuTermsQuery.generated';

import { useRestaurant } from '../../utils/withRestaurant';
import { toCamelCase } from '../../utils/strings';
import { AH } from './AccessibleHeading';
import Loading from '../../shared/Loading';

const useMessages = () => {
  const intl = useIntl();

  return {
    clientAccessibility: intl.formatMessage({ defaultMessage: 'Website Accessibility', id: 'ordering_app.terms.client_accessibility' }),
    clientTerms: intl.formatMessage({ defaultMessage: 'Client Terms of Service', id: 'ordering_app.terms.client_terms' }),
    dmcaPolicy: intl.formatMessage({ defaultMessage: 'DMCA Policy', id: 'ordering_app.terms.dmca_policy' }),
    giveawayTerms: intl.formatMessage({ defaultMessage: 'Giveaway Terms', id: 'ordering_app.terms.giveaway_terms' }),
    privacy: intl.formatMessage({ defaultMessage: 'Privacy Policy', id: 'ordering_app.terms.privacy' }),
    terms: intl.formatMessage({ defaultMessage: 'Terms', id: 'ordering_app.terms.terms' }),
  };
};

type TermsContainerProps = {
  termsType: 'clientAccessibility' | 'clientTerms' | 'dmcaPolicy' | 'giveawayTerms' | 'privacy' | 'terms'
  id?: string
}

const TermsContainer = ({ termsType, id = 'terms-panel' }: TermsContainerProps) => {
  const restaurant = useRestaurant();
  const giveawayAmount = restaurant?.giveawayAmount || '$500';
  const messages = useMessages();
  const { data, loading } = useQuery(PopmenuTermsDocument, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !data || !data.popmenuTerms) {
    return <Loading />;
  }

  let terms: string | React.ReactNode[] = data.popmenuTerms[toCamelCase(termsType) as keyof Omit<PopmenuTermsType, 'id'>];
  terms = terms.replace(/{{ review_reward_amount }}/g, giveawayAmount);
  terms = reactStringReplace(terms, '{{ ccpa_request_link }}', () => (
    <a href="https://my.popmenu.com/do-not-sell-my-personal-info" rel="noopener" target="_blank">
      <FormattedMessage id="consumer.reviews.here" defaultMessage="here" />
    </a>
  ));

  return (
    <div id={id}>
      <AH typography align="center" gutterBottom variant="h4">
        {messages[termsType]}
      </AH>
      <Typography style={{ whiteSpace: 'pre-wrap' }}>
        {terms}
      </Typography>
    </div>
  );
};

export default TermsContainer;
